<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.structure.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title" :class="checkRTL">
        <app-i18n
          code="entities.structure.list.title"
        ></app-i18n>
      </h1>

      <app-structure-list-toolbar></app-structure-list-toolbar>
      <app-structure-list-filter></app-structure-list-filter>
      <app-structure-list-table></app-structure-list-table>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import StructureListFilter from '@/modules/structure/components/structure-list-filter.vue';
import StructureListTable from '@/modules/structure/components/structure-list-table.vue';
import StructureListToolbar from '@/modules/structure/components/structure-list-toolbar.vue';

export default {
  name: 'app-structure-list-page',

  components: {
    [StructureListFilter.name]: StructureListFilter,
    [StructureListTable.name]: StructureListTable,
    [StructureListToolbar.name]: StructureListToolbar,
  },

  computed: {
    ...mapGetters({
      checkRTL: 'structure/form/checkRTL',
    }),
  },

};
</script>

<style></style>
