<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: `/structure/new` }"
      v-if="hasPermissionToCreate"
    >
      <el-button icon="el-icon-fa-plus" type="primary" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="common.new"></app-i18n> </span>
      </el-button>
    </router-link>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToDestroy"
    >
      <span>
        <el-button
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
          :class="rtlAndPadding"
        >
         <span class="card-title"> <app-i18n code="common.destroy"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

    <router-link
      :to="{
        path: '/audit-logs',
        query: { entityNames: 'structure' },
      }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="auditLog.menu"></app-i18n> </span>
      </el-button>
    </router-link>

    <!--    <el-tooltip :content="exportButtonTooltip" :disabled="!exportButtonTooltip">-->
    <!--      <span>-->
    <!--        <el-button-->
    <!--          :disabled="exportButtonDisabled"-->
    <!--          @click="doExport()"-->
    <!--          icon="el-icon-fa-file-excel-o"-->
    <!--        >-->
    <!--          <app-i18n code="common.export"></app-i18n>-->
    <!--        </el-button>-->
    <!--      </span>-->
    <!--    </el-tooltip>-->
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { StructurePermissions } from '@/modules/structure/structure-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-structure-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'structure/list/hasRows',
      loading: 'structure/list/loading',
      exportLoading: 'structure/list/exportLoading',
      selectedRows: 'structure/list/selectedRows',
      destroyLoading: 'structure/destroy/loading',
      rtlAndPadding: 'structure/form/rtlAndPadding'
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new StructurePermissions(this.currentUser)
        .create;
    },

    hasPermissionToEdit() {
      return new StructurePermissions(this.currentUser)
        .edit;
    },

    hasPermissionToImport() {
      return new StructurePermissions(this.currentUser)
        .import;
    },

    hasPermissionToDestroy() {
      return new StructurePermissions(this.currentUser)
        .destroy;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'structure/list/doExport',
      doRemoveAllSelected:
        'structure/list/doRemoveAllSelected',
      doDisableAllSelected:
        'structure/list/doDisableAllSelected',
      doEnableAllSelected:
        'structure/list/doEnableAllSelected',
      doDestroyAll: 'structure/destroy/doDestroyAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
